import * as React from 'react'

const Logo = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 48.2 48.2"
    className={className ?? ''}
  >
    <path d="M45.5,31.9c-0.8-2.8-1.8-5.2-2.9-7.3c-1-1.9-2.4-3.8-4.3-6c-2.3-2.6-4.5-4.3-7-5.3l-0.1,0
      c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.3,0-0.4,0.1c-0.5,0.3-1,0.5-1.5,0.7c-1.3,0.5-2.6,1-3.5,2.2c-0.4,0.5-1.4,0.8-2.4,0.8
      c-0.6,0-1.2-0.1-1.6-0.4c-0.4-0.3-0.7-0.5-1.1-0.8c-1.5-1.1-3-2.2-4.9-2.7c0,0,0,0,0,0l0.5-0.3c0.2-0.1,0.4-0.4,0.3-0.7l-1-2.2
      l-1-2.1c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.1L14.3,8c0.9,1.1,1.8,2.3,2.5,3.5c0.6,1.1,1.4,1.7,2.3,1.7c0.5,0,1.1-0.2,1.8-0.6
      c1.1-0.7,2.4-1.1,3.3-1.3c0.1,0,0.4,0.3,0.6,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.4,0.4,0.7,0.6c0.1,0.1,0.2,0.2,0.3,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.2,0.3-0.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.2-0.7,0.2-1
      c0.1-0.5,0.1-1,0.1-1.5c0.1-0.8,0.1-1.7,0.3-2.5C27.8,7,27.9,6.9,28,6.9c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.4,0,0.7,0,1.2
      c0,0.2,0,0.8,0,1c0,0.8,0.4,2.1,2,2.1h0.1c0.2,0,0.4-0.5,0.5-0.8c0.1-0.5,0.2-1.2,0.3-1.7c0.2-1.1,0.4-2.4,0.6-3.5
      c0.2-1.4,1.4-3,2.6-3.4c0.1,3.8-0.4,6.8-2.9,9.3c-0.1,0.1-0.2,0.4-0.1,0.6l0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.5-0.2,0.7-0.4c0.6-0.3,1.3-0.6,1.7-1.1c2-2.3,2.6-5.3,1.9-9.2C36.3,0.8,35.4,0,34.2,0
      c-0.8,0-1.5,0.4-2.1,1c-0.8,0.9-1.3,1.7-1.6,2.6c-0.3,0.7-0.5,0.7-0.6,0.7c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-1-0.4-1.4-0.4
      c-0.7,0-1.3,0.3-1.6,1c-0.6,1-1.1,2.2-1.7,3.6c-0.2,0.7-0.4,0.8-1,0.9c-1.1,0.1-2.3,0.3-3.4,0.8c-0.3,0.1-0.6,0.2-0.8,0.2
      c-0.3,0-0.7-0.1-1.2-0.8c-1.5-1.8-3.2-3.4-4.6-4.8C13.2,4.2,12.8,4,12.4,4c-0.3,0-1,0.1-1.2,1.4c-0.1,0.4-0.1,0.8-0.1,1.3
      c0,0.1,0,0.1,0,0.2c-0.3-0.1-0.6-0.1-0.8-0.3c-0.3-0.2-0.7-0.4-1-0.6C8.9,5.9,8.5,5.6,8.1,5.4c-0.3-0.1-0.7-0.2-1-0.2
      c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.9,1-0.7,1.8c0.6,2.7,2,6.3,6.6,6.8c0.2,0,0.2,0,0.3,0.1c0,0.1-0.1,0.2-0.2,0.3
      c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.4-0.9,0.7-1.2,1.2c-1.9,2.7-3.8,5.6-5.5,8.1c-2.3,3.5-2.6,7.4-2.5,10.4c0,0.9,0.1,2.1,0.6,3
      c2,3.4,4.2,5.3,7.1,6.1l0.4,0.1c2.6,0.7,5.3,1.5,7.8,2.5c3.2,1.2,5.9,1.7,8.4,1.7c1.6,0,3.1-0.2,4.6-0.7c1.7-0.5,3.3-1.3,4.9-2
      c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.2,0.6-0.5,0.9-0.8c0.1-0.1,0.1-0.2,0.2-0.2c1,0,2.3-0.8,3.4-2.1c0.9-1,1.9-2.5,1.9-3.9l0.6-0.1
      c0.2,0,0.3-0.2,0.4-0.3c0-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.7,0.3-1c0-0.3,0.1-0.7,0.1-1.1C45.6,33.9,45.8,32.9,45.5,31.9z
      M12.7,12.1c-0.2,0-0.3,0-0.4-0.1c-2.1-0.9-3.5-2.3-4.1-4.2c0-0.1,0.1-0.3,0.1-0.5c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.4
      c0.7,0.5,1.3,0.8,1.7,1.3c0.8,0.7,1.5,1.6,2.3,2.6c0.1,0.1,0.1,0.4,0.1,0.6C12.8,12.1,12.7,12.1,12.7,12.1z M13.1,24
      c0.6,0.5,1.3,0.8,2,1.1c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.2,1.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.5-0.8,0.9-1.2,1.2
      c-0.3,0.2-0.6,0.3-0.9,0.3c-0.9,0-1.8-0.8-2.4-2.1c-0.8-1.6-0.7-3.3-0.5-5l0-0.2c0-0.2,0.1-0.4,0.3-0.6c0,0,0.1-0.1,0.2-0.1
      c0,0.1,0.1,0.1,0.1,0.2C11.5,21.5,11.9,23,13.1,24z M29.6,15.2c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0.4,2.1,1.3c1.1,1.3,2.1,2.8,3,4.4
      c0.7,1.3,0.1,2.4-0.8,3.9c-0.6,0.9-1.3,1.7-2.1,2.5c-0.4,0.4-0.7,0.6-1.1,0.6c-0.3,0-0.7-0.1-1.1-0.4c-0.8-0.6-1.4-1.3-1.8-2.1
      c0.1,0,0.1,0.1,0.2,0.1c0.9,0.7,1.7,1.1,2.5,1.1c0.5,0,1-0.1,1.5-0.4c1-0.5,1.5-2.4,1.2-3.8c-0.2-0.6-0.5-1.2-0.8-1.7
      c-0.1-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.3-0.3-0.3-0.4c-0.1-0.4-0.5-1.5-1.5-1.5c-0.2,0-0.5,0.1-0.7,0.2c-1,0.5-1.8,1.1-2.4,1.9
      c-0.3,0.5-0.3,1-0.4,1.6c0,0.3,0,0.5-0.1,0.7l0,0.1c0,0.2-0.1,0.6-0.2,0.7c0,0-0.1,0-0.1,0c-0.2,0-0.5-0.1-0.7-0.1
      c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-1-0.9-2-1.4-3.1-1.4c-0.7,0-1.4,0.2-2.1,0.6
      c-0.2,0.1-0.4,0.2-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.6c-0.2-0.9-0.7-2.3-2.4-2.9c-0.4-0.1-0.8-0.6-1.1-1c-0.3-0.4-1.1-0.4-1.7-0.4
      c-0.3,0-0.7,0-1,0.1c-0.1,0-0.1,0-0.1,0l-0.3,0.1c0.2-1,0.9-1.6,2.1-2.1c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.1,0.3,1.7,0.8
      c1.1,1,2.3,2,3.6,2.6c0.9,0.4,1.8,0.6,2.8,0.6c1,0,2.1-0.2,3.4-0.6c1.1-0.4,1.8-0.9,2.2-1.6C29.7,16.5,29.8,15.9,29.6,15.2z
      M24.8,26.1c-0.1,0.6-0.4,0.9-0.5,0.9c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.9-1.1-1.4-2.3-1.6-3.6l0,0c0.2,0.1,0.3,0.2,0.5,0.3
      c0.6,0.4,1.3,0.9,1.8,1.3C24.8,25.2,24.9,25.6,24.8,26.1z M18.3,27.9c0.5-0.7,1-1.3,1.5-1.8c0.1-0.1,0.2-0.2,0.3-0.2
      c0.1,0,0.2,0.1,0.3,0.4c0.6,1,1.1,2.1,1.7,3.1c0.3,0.6,0.6,1.2,0.9,1.8c0.2,0.3,0.3,0.6,0.5,0.8c0.3,0.4,0.8,0.7,1.2,0.7
      c0.5,0,1-0.4,1.2-1c0.2-0.5,0.3-1,0.4-1.5c0.1-0.5,0.3-1,0.4-1.4c0.1-0.4,0.4-0.7,0.4-0.7c0.2,0,0.6,0.2,0.8,0.4
      c1.1,1.1,2.3,1.6,3.5,1.6c1.4,0,2.7-0.8,3.8-2.2c1-1.4,2.1-3.1,2.4-5.2c0,0,0-0.1,0.1-0.2c0.3,0.1,0.6,0.1,0.7,0.2
      c1.9,2.5,3.5,5.4,4.6,8.4c0.2,0.5,0.2,1,0.2,1.7c0,0.4,0,0.8,0.1,1.2c0,1.6-1,3.3-2.1,4.9c-1.4,2.1-3.7,3.4-5.8,4.6L35,43.9
      c-2.9,1.7-5.9,2.7-9,2.7h-0.1c-1.4,0-2.6-0.4-3.6-0.7c-2.6-0.8-5.2-1.8-7.8-2.7c-1.5-0.5-3-1.1-4.4-1.6c-3.3-1.1-5.7-5.1-5.5-8.9
      c0.2-3.3,1.7-6.2,3-8.6C7.8,24,7.9,23.8,8,23.6c0.1-0.3,0.3-0.6,0.4-0.8c0.1-0.1,0.3-0.3,0.4-0.3c0,0.1,0.2,0.2,0.2,0.4
      c0.2,0.4,0.3,0.9,0.5,1.4c0.2,0.7,0.5,1.4,0.8,2.1c0.8,1.7,2.2,2.8,4,3.2c0.1,0,0.3,0,0.5,0C15.9,29.8,17.6,28.9,18.3,27.9z"/>
    <path d="M22.5,37.5c0-0.5-0.2-0.7-0.5-0.9l-0.1,0.1h-0.2c-0.3,0-0.6-0.2-0.8-0.2c-0.5,0-0.9-0.1-1.2-0.1
      c-0.5-0.1-0.9-0.2-1.3-0.2c-1.1,0-1.7,0.6-2.3,1.5c-0.5,0.7-0.7,0.8-0.7,0.8c-0.1,0-0.3-0.2-0.8-1l-0.1-0.2
      c-0.5-0.8-1.1-1.8-2.8-1.7l-0.7,0.1l0.4,0.7c0.3,0.5,0.5,1,0.7,1.4c0.6,1.1,1.1,2.2,1.8,3.1c0.5,0.7,1.6,1.1,2.3,1.2l0.1,0
      c0.7,0,1.4-1.2,1.5-1.6c0.4-1.1,0.5-1.1,0.8-1.1c0.2,0,0.5,0,1,0.2c0.2,0.1,0.5,0.1,0.8,0.1c0.4,0,1.2-0.1,1.7-0.6
      C22.3,38.7,22.5,38.1,22.5,37.5z"/>
    <path d="M27.8,39l-0.3-0.8l-0.8,1c-0.8,1-1.5,2-2.5,2c-0.4,0-1-0.2-1.5-0.6l-1.3-0.9l0.6,1.5c0.1,0.3,0.2,0.5,0.3,0.7
      c0.2,0.4,0.3,0.8,0.5,1.1c0.4,0.8,1,1.2,1.7,1.2c0.6,0,1.2-0.3,1.8-0.6C28.1,42.5,28.6,41.1,27.8,39z"/>
  </svg>
)

export default Logo